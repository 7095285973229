<template>
  <div class="second-text">
    {{ formattedDate }}
  </div>
</template>

<script>
/**
 * component for displaying date in datatable columns
 * api name = date
 */
export default {
  name: "DateColumn",
  props: ["value"],
  computed: {
    formattedDate() {
      if (!this.value) return "";

      // Convert seconds to milliseconds
      const date = new Date(this.value * 1000);

      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    },
  },
};
</script>
