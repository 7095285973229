<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    class="h-100"
    max-width="1100"
  >
    <template v-slot:activator="{ on, attrs }" v-if="value.header">
      <button
        class="btn btn-light-facebook btn-sm px-4 custom-btn text-no-wrap"
        @click="toggleModal"
      >
        View
      </button>
    </template>
    <div
      class="bg-white poppins h-100 d-flex flex-column justify-justify-content-between justify-center"
    >
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">View SKUs</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <v-card-text class="px-8 pt-6 pb-0 h-100 min-h-250px">
        <div class="table-responsive overflow-y-auto" style="max-height: 70vh">
          <v-data-table
            :headers="value.header.map((item) => ({ text: item, value: item }))"
            :items="tableData"
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:item.Serials="{ item }">
              <v-btn
                type="reset"
                class="btn btn-light mr-3 px-5 py-3 ls1"
                @click="showData(item.Serials)"
              >
                Show
              </v-btn>
            </template>
          </v-data-table>
        </div>
      </v-card-text>

      <!-- <v-card-actions class="">
        <v-spacer></v-spacer>
        <button
          type="reset"
          class="btn btn-light-info mr-3 px-5 py-3 ls1"
          @click="dialog = false"
        >
          Close
        </button>
      </v-card-actions> -->
    </div>
    <v-dialog
      v-model="dialog2"
      transition="dialog-bottom-transition"
      persistent
      max-width="800"
    >
      <div
        class="bg-white poppins h-100 d-flex flex-column justify-justify-content-between justify-center"
      >
        <div class="modal-header py-4 align-center">
          <h4 class="mb-0 font-weight-bolder">Serials</h4>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="toggleModal2"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <v-card-text class="px-8 pt-6 pb-0 h-100">
          <div
            class="table-responsive overflow-y-auto"
            style="max-height: 75vh"
          >
            <div v-if="detail">{{ detail }}</div>
            <h3 v-else class="text-center font-weight-medium pt-3">
              No record found
            </h3>
          </div>
        </v-card-text>

        <v-card-actions class="pb-7">
          <v-spacer></v-spacer>
          <button
            type="reset"
            class="btn btn-light-info mr-3 px-5 py-3 ls1"
            @click="toggleModal2"
          >
            Close
          </button>
        </v-card-actions>
      </div>
    </v-dialog>
  </v-dialog>
</template>

<script>
export default {
  name: "ShowSkusExTable",
  props: ["value"],
  data: () => ({
    dialog: false,
    dialog2: false,
    detail: null,
  }),
  computed: {
    tableData() {
      return this.value.body.map((val) => {
        let a = {};
        val.forEach((v, i) => {
          a[this.value.header[i]] = v;
        });
        return a;
      });
    },
  },
  methods: {
    showData(data) {
      this.detail = data;
      this.toggleModal2();
    },
    toggleModal() {
      this.dialog = !this.dialog;
    },
    toggleModal2() {
      if (this.dialog2) this.detail = null;
      this.dialog2 = !this.dialog2;
    },
  },
};
</script>
