<template>
  <v-dialog
    v-model="export_modal"
    persistent
   
    transition="dialog-bottom-transition"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Export</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="export_modal = false"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div
        class="modal-body d-flex flex-column h-550px scroll-y mx-5 mx-xl-15 py-7"
      >
        <!--begin::Body-->
        <div>
          <div class="ma-3">
            <v-card class="ma-0 pa-0 elevation-0">
              <v-card-title class="ma-0 py-0">
                <v-switch
                  @click="selectAllColumns"
                  v-model="select_all"
                  label="Select all"
                  dense
                />
              </v-card-title>
              <v-card-text class="elevation-0 px-1 py-1">
                <perfect-scrollbar
                  class="scroll overflow-y-hidden py-0"
                  style="max-height: 55vh"
                >
                  <draggable
                    tag="div"
                    :list="columns_to_export"
                    class=""
                    handle=".dragger"
                    v-bind="dragOptions"
                    @start="isDragging = true"
                    @end="isDragging = false"
                  >
                    <transition-group type="transition" name="flip-list">
                      <v-card
                        class="px-2 py-0 mx-1 d-flex align-center justify-start elevation-1 my-1"
                        v-for="(element, idx) in columns_to_export"
                        :key="idx"
                      >
                        <i class="dragger"><v-icon>mdi-menu</v-icon></i>

                        <v-switch
                          class="pl-4"
                          dense
                          v-model="element['exportable']"
                          @change="updateData"
                        />

                        <div class="pl-2" style="font-size: 16px">
                          {{ element.text }}
                        </div>
                        <!-- <v-spacer></v-spacer>
                    <i class="close pr-2" @click="removeAt(idx)"
                      ><v-icon>mdi-delete</v-icon></i
                    > -->
                      </v-card>
                    </transition-group>
                  </draggable>
                </perfect-scrollbar>
              </v-card-text>
            </v-card>
          </div>
        </div>

        <!--end::Body-->

        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="toggleModal"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click.prevent="exportColumns"
          >
            Export
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import draggable from "vuedraggable";
import Swal from "sweetalert2";
export default {
  name: "DataTableExport",
  props: {
    headers: {
      required: true,
      type: Array,
    },
    downloadItem: {
      required: true,
      type: Function,
    },
  },
  components: { draggable },
  data: () => ({
    select_all: true,
    export_modal: false,
    columns_to_export: [],
  }),
  mounted() {
    this.initiate();
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    initiate() {
      this.columns_to_export = [...this.headers];
      this.columns_to_export.forEach((col, index) => {
        this.columns_to_export[index] = { exportable: true, ...col };
      });
      this.selectAllColumns();
    },
    /**
     * to trigger computed data!
     */
    updateData() {
      let data = [...this.columns_to_export];
      this.columns_to_export = data;
    },
    /**
     * convert data to old format to be compatible
     */
    convertToOldStyle() {
      let data = [];
      this.columns_to_export.forEach((col) => {
        if (col["exportable"]) {
          data.push(col.value);
        }
      });
      return data;
    },
    /**
     * handle submit button action
     */
    exportColumns() {
      if (this.columns_to_export.length > 0) {
        this.downloadItem(this.convertToOldStyle());
      } else {
        Swal.fire({
          title: "Warning",
          text: `Please, select at least one column to export!`,
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    },
    /**
     * change state of columns
     */
    selectAllColumns() {
      if (this.select_all) {
        this.columns_to_export.forEach((col, index) => {
          this.columns_to_export[index]["exportable"] = true;
        });
      } else {
        this.columns_to_export.forEach((col, index) => {
          this.columns_to_export[index]["exportable"] = false;
        });
      }
    },
    /**
     * toggle modal visibility
     */
    toggleModal() {
      if (!this.export_modal) {
        this.initiate();
      }
      this.export_modal = !this.export_modal;
    },
    /**
     * is all columns are exportable
     * @return {Boolean}
     */
    isAllColumnsExportable() {
      let is_all_exportable = true;
      this.columns_to_export.forEach((col) => {
        if (!col["exportable"]) {
          is_all_exportable = false;
        }
      });
      return is_all_exportable;
    },
    /**
     * select all state manager
     */
    handleSelectAllState() {
      if (this.isAllColumnsExportable()) {
        this.select_all = true;
      } else {
        this.select_all = false;
      }
    },
  },
  watch: {
    columns_to_export: function () {
      this.handleSelectAllState();
    },
  },
};
</script>

<style scoped>
.dragger {
  cursor: move;
}
</style>
